import {
  memo,
  FunctionComponent,
  SyntheticEvent,
  useState,
  useMemo,
  useCallback,
} from 'react';
import { Game as GameType } from '~types';
import { useTranslation } from 'react-i18next';
import { useSubscription, gql } from '@apollo/client';
import { NavLink } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import playAudio from '~frontend/utils/playAudio';

import GameChange from '../../game/GameChange';
import Currency from '../../Currency';
import { GetName, SteamItemImage } from '../../item';
import { Price } from '~ui/index';

import IconStatus1 from '../../../scss/images/icon-profile-3.svg';
import IconStatus2 from '../../../scss/images/icon-profile-4.svg';
import IconStatus3 from '../../../scss/images/icon-profile-5.svg';
import IconStatus5 from '../../../scss/images/icon-profile-7.svg';
import IconStatus6 from '../../../scss/images/icon-profile-6.svg';
import IconProfile9 from '../../../scss/images/icon-profile-9.svg';
import IconProfile10 from '../../../scss/images/icon-profile-10.svg';
import IconProfile11 from '../../../scss/images/icon-profile-11.svg';
import IconProfileCase from '../../../scss/images/icon-btn-case.svg?react';
import { useOpenModal } from '~frontend/components/modals/hooks';
import { useConfig } from '~components/hooks';
import { game } from 'telegraf/typings/button';
import Up from './up';
import Down from './down';
import { Button } from '~shared/frontend/ui/button/button';
import Upgrade from './upgrade';

interface ProfileItemComponentType {
  getGame: GameType;
  isUser: boolean;
  isProfileRight?: boolean;
}

interface ItemWrapComponentSubscriptionData {
  updateGame: GameType;
}

interface ItemWrapComponentSubscriptionVars {
  id: number;
}

const Item: FunctionComponent<ProfileItemComponentType> = ({
  getGame,
  isUser,
  isProfileRight,
}) => {
  const { t } = useTranslation();
  const [active, setActive] = useState('');
  const { onShow } = useOpenModal();
  const getConfig = useConfig();

  const onClick = (event: SyntheticEvent) => {
    if (getGame.type === 5 && getConfig.seo.distributionActive) {
      event.preventDefault();

      onShow('DistributionWinModal', {
        id: getGame.distributionGameId,
      });
    }
  };

  const onToogle = (event: SyntheticEvent) => {
    //SCRUM-151 comment to hide hover with +3%
    // if (event.type === 'mouseover') {
    //   setActive('hover');
    // } else {
    //   setActive('');
    // }
  };

  useSubscription<
    ItemWrapComponentSubscriptionData,
    ItemWrapComponentSubscriptionVars
  >(
    gql`
      subscription updateGame($id: Int!) {
        updateGame(id: $id) {
          id
          status
          getStatus
          price
        }
      }
    `,
    { variables: { id: getGame?.id } },
  );

  // Объект с сопоставлением цветов и названий классов
  const rarityColors = {
    b0c3d9: 'profile_item_consumer',
    '4b69ff': 'profile_item_military',
    '5e98d9': 'profile_item_industrial',
    '8847ff': 'profile_item_restricted',
    d32ce6: 'profile_item_classified',
    eb4b4b: 'profile_item_covert',
    ffc600: 'profile_item_legendary',
  };

  // Функция для получения названия класса по цвету
  const getRarityClassName = useCallback((color) => {
    console.log('color', color);
    return rarityColors[color] || '';
  }, []);

  const rarityClassName = useMemo(
    () => getRarityClassName(getGame?.getItem.color),
    [getGame?.getItem.color, getRarityClassName],
  );

  return (
    <>
      {' '}
      <div
        className={`profile_item ${rarityClassName} ${
          ![1, 0].includes(getGame?.status) && !isUser
            ? 'profile_item_foreign'
            : ''
        }`}
      >
        <div className="item_price">
          {getGame?.status === 2 && <Up className="price_icon" />}
          {getGame?.status === 3 && <Down className="price_icon" />}
          {getConfig.isEU ? (
            <>
              <Currency />
              <Price sum={getGame?.price} />
            </>
          ) : (
            <>
              <Price sum={getGame?.price} />
              <Currency />
            </>
          )}
        </div>
        <div className="item_percent">{getGame?.chance}%</div>
        <div className="item_image_container">
          <SteamItemImage
            url={getGame?.getItem.getImage}
            size="144x108"
            alt={getGame?.getItem.getName}
            className="item_image"
          />
        </div>
        <div className="item_info">
          <div className="item_name">
            {getGame?.getItem.getName.split('|')[0]}
          </div>
          <div className="item_skin">
            <span>{getGame?.getItem.quality}</span> |{' '}
            {getGame?.getItem.getName.split('|')[1]}
          </div>
        </div>
        <div className="item_button">
          {getGame?.status === 1 && !isUser ? (
            <div className="item_button_container">
              <GameChange
                className="btn1_green btn_green"
                getGame={getGame}
                type={0}
                onMouseOver={onToogle}
                onMouseLeave={onToogle}
              >
                <span>{t('Sell')}</span>
              </GameChange>
              <GameChange
                className="btn1_blue btn_icon"
                getGame={getGame}
                type={3}
              >
                <span>
                  <Upgrade />
                </span>
              </GameChange>

              {/* <Button text="Продать" className={`btn1_green btn_green`} />
              <Button
                is_icon={true}
                icon={<Upgrade />}
                className={`btn1_blue btn1_icon`}
              /> */}
            </div>
          ) : [4, 9].includes(getGame?.status) && !isUser ? (
            <Button
              text={t(getGame?.getStatus)}
              type={'button'}
              disabled={true}
              className={`btn1_dark btn1_dark`}
            />
          ) : (
            <Button
              text="Перейти к кейсу"
              href={getGame?.getCase?.getUrl}
              className={`btn1_dark btn1_dark`}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default memo(Item);
